import React, { useState } from 'react';
import {Row, Col, Container} from "react-bootstrap";
import {logoFooter, dabplus, logoSmartive, twitterX} from "../images";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookSquare, faYoutubeSquare, faInstagramSquare} from '@fortawesome/free-brands-svg-icons'


function Footer() {
    const [isHover, setIsHover] = useState(false);

    const iconStyle = {
        width: '0.875em',
        filter: isHover ?
          'brightness(0) saturate(100%) invert(13%) sepia(94%) saturate(5213%) hue-rotate(354deg) brightness(87%) contrast(105%)' :
          'brightness(0) saturate(100%) invert(59%) sepia(17%) saturate(0%) hue-rotate(197deg) brightness(99%) contrast(99%)'
    };

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return (
        <>
         <Container fluid style={{maxWidth: '1410px', position: 'relative', marginTop: '56px'}}>
            <Row>
                <div id="AADIV54"></div>
            </Row>
         </Container>
            <footer>
                <Container fluid style={{maxWidth: '1410px'}}>
                    <Row>
                        <Col md={3} className='mb-4'>
                            <div className='d-flex align-content-center mb-0'>
                                <div className='mr-3'>
                                    <img src={logoFooter} alt=""/>
                                </div>
                                <div className='footer-top-style text-dark'>
                                    <p className='m-0'>Radio Kielce </p>
                                    <p className='m-0'><span className='text-danger'>101,4</span> MHz</p>
                                </div>
                            </div>


                            <p className='m-0'>FM 90,4 MHz | 100,0 MHz Włoszczowa</p>
                            <p className='m-0'>
                                <img style={{width: '50px'}} src={dabplus} alt="dabplus"/>
                                <span style={{position: 'relative', top: '3px', left: '5px'}}>215,072 MHz / KANAŁ 10D</span>
                            </p>

                        </Col>
                        <Col md={3} className='mb-4'>
                            <address className='font-weight-bold text-dark mb-0'>
                                <p className='m-0'>ul. Radiowa 4, 25-317 Kielce</p>
                                <p className='m-0'>tel. 41 368 12 00</p>
                                <p className='m-0'>fax 41 344 65 44</p>
                            </address>
                            <p className='m-0'><a href="mailto:radio@radiokielce.pl">radio@radiokielce.pl</a></p>
                            <p className='m-0'><a href="https://radiokielce.pl">radiokielce.pl</a></p>
                        </Col>
                        <Col md={3} className='mb-4'>
                            <ul className='list-unstyled'>
                                <li><a className='footer-href' href="https://radiokielce.pl/audycje-radia-kielce/" target='_blank'>Nasze audycje</a></li>
                                <li><a className='footer-href' href="https://radiokielce.pl/ramowka/" target='_blank'>Ramówka</a></li>
                                <li><a className='footer-href' href="https://radiokielce.pl/kontakt/" target='_blank'>Kontakt</a></li>
                                <li><a className='footer-href' href="https://radiokielce.pl/polityka-prywatnosci/" target='_blank'>Polityka prywatności</a></li>
                            </ul>
                        </Col>
                        <Col md={3} className='d-flex justify-content-center justify-content-lg-end'>
                            <a rel='nofollow' href='https://www.facebook.com/Polskie.Radio.Kielce?fref=ts'
                               target='_blank'>
                                <FontAwesomeIcon icon={faFacebookSquare} className='socialIcon'/>
                            </a>
                            <a rel='nofollow' href='https://x.com/radiokielce' target='_blank'>
                                <img
                                  src={twitterX}
                                  style={iconStyle}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}
                                  className="socialIcon" alt=""/>
                            </a>
                            <a rel='nofollow' href='https://www.youtube.com/user/PolskieRadioKielce' target='_blank'>
                                <FontAwesomeIcon icon={faYoutubeSquare} className='socialIcon'/>
                            </a>
                            <a rel='nofollow' href='https://www.instagram.com/radio_kielce' target='_blank'>
                                <FontAwesomeIcon icon={faInstagramSquare} className='socialIcon'/>
                            </a>
                        </Col>
                    </Row>

                </Container>
            </footer>
            <Container className='py-3 mb-100' fluid style={{maxWidth: '1410px'}}>
            <Row>
                <Col md={6} className='text-center text-md-left'>
                    <p className='footer-copywrite m-0'>Wszelkie prawa zastrzeżone. Polskie Radio Kielce SA 1952 - 2024</p>
                </Col>
                <Col md={6} className='d-flex justify-content-center justify-content-md-end align-items-center'>
                    <img src={logoSmartive} alt="logo smartive" style={{width: '20px'}}/>&nbsp;
                    <a href="https://smartive.app/" target="_blank" className='footer-copywrite'>Smartive.app</a>
                </Col>
            </Row>
            </Container>
        </>
    )
}


export default Footer
