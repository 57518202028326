export const radioKielce = require('./radiokielce.png');
export const folkRadio = require('./folkradio.png');
export const logo = require('./logo.png');
export const logoFooter = require('./logo_footer.png');
export const play = require('./play.png');
export const stop = require('./stop.png');
export const gray = require('./gray.png');
export const search = require('./search.png');
export const bgRight = require('./bgRight.png');
export const dotsRight = require('./dotsRight.png');
export const shapeRight = require('./shapeRight.png');
export const playButton = require('./playButton.png');
export const sub1 = require('./sub1.png');
export const sub2 = require('./sub2.png');
export const sub3 = require('./sub3.png');
export const sub4 = require('./sub4.png');
export const sub5 = require('./sub5.png');
export const playIconWhite = require('./playIconWhite.png');
export const stopIconWhite = require('./stopIconWhite.png');
export const dabplus = require('./dabplus_logo_grey.png');
export const podcastCover = require('./podcastCover.png');
export const soundWaves = require('./soundWaves.png');
export const logoSmartive = require('./logo_smartive.png');
export const og_podcasty = require('./og_podcasty.png');
export const banner = require('./baner-podcasty.jpg');
export const bannerDesktop = require('./baner-podcasty-desktop.jpg');
export const twitterX = require('./square-x-twitter.svg');
