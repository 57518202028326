import React from 'react';
import {sub1, sub3, sub4, sub5} from "../images";

function Subscribe(props) {

    const { appleLink, spotifyLink, deezerLink, spreakerLink } = props;

    return (
        <div className="subscribeBox p-3">
            <p className="subscribeText my-2">
                Subskrybuj
            </p>
          <div className='d-flex'>
            <a href={appleLink} className='subscribeBorder'>
              <img src={sub3} alt=""/> Apple Podcasts
            </a>
            {/*<a href={googleLink} className='subscribeBorder'>*/}
            {/*    <img src={sub2} alt=""/> Google Podcasts*/}
            {/*</a>*/}
            <a href={deezerLink} className='subscribeBorder'>
              <img src={sub5} alt=""/> Deezer
            </a>
          </div>
          <div className='d-flex'>
            <a href={spotifyLink} className='subscribeBorder'>
              <img src={sub1} alt=""/> Spotify
                </a>
                <a href={spreakerLink} className='subscribeBorder'>
                    <img style={{width: '71px'}} src={sub4} alt=""/>
                </a>
            </div>
        </div>
    )
}


export default Subscribe

