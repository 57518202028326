import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row, Button} from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import {dotsRight, play, stop} from "../images";
import Subscribe from "../components/subscribe";
import {useParams} from "react-router";
import {getPodcastById, fetchAll} from "../api/podcastsAPI";
import PopularBox from "../components/popularBox";
import Loader from "react-loader-spinner";
import {connect} from "react-redux";
import {setPlayer} from "./../actions"
import {Link} from "react-router-dom";
import {Accordion} from "react-bootstrap";
import Card from "react-bootstrap/Card";


const Podcast = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let {id} = useParams();

    const [epizodes, setEpizodes] = useState([]);
    const [selectedPodcast, setSelectedPodcast] = useState(null);
    const [loading, setLoading] = useState(true);
    const [idplay, setIdplay] = useState(null);
    const [showOnPage, setShowOnPage] = useState(5);
    const [toggleText, setToggleText] = useState(true);
    const [descHeight, setDescHeight] = useState(0);
    const descriptionPodcast = useRef(null);



    useEffect(() => {
        if(props.player.id){
            setIdplay(props.player.id)
        }
    }, [props.player.id])



    const toggleDescription = () => {

        setToggleText(!toggleText);

    };


    const setHeightElement = () => {
        if (descriptionPodcast.current) {
            setDescHeight(descriptionPodcast.current.scrollHeight);
        }
    };



    const getMainPodcast = (podcasts) => {

        let tmp;

        podcasts.map(item => {
            if (item.friendly_url === id) {
                tmp = {...item};
            }
        });

        return tmp;

    };

    const getPodcast = (id) => {
        getPodcastById(id)
            .then(response => {
                if (response) {
                    Array.isArray(response) ? setEpizodes(response) : setEpizodes([])
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setEpizodes([])
            })
    };

    const returnFriendlyUrl = (tab, id) => {

        tab.forEach(item => {
            if (item.friendly_url === id) {
                getPodcast(item.id);

                if (props.player.play) {
                    document.title = `▶ ${item.title} - Podcasty Radia Kielce`;
                } else {
                    document.title = `${item.title} - Podcasty Radia Kielce`;
                }


            }
        })

    };

    const getAllPodcasts = () => {
        fetchAll()
            .then(response => {
                if (response) {
                    setSelectedPodcast(getMainPodcast(response));
                    returnFriendlyUrl(response, id)
                    setTimeout(setHeightElement, 1000)
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    useEffect(() => {
        getAllPodcasts()
    }, [id]);


    useEffect(() => {

        if (props.player.play) {
            document.title = `▶ ${props.player.podcastTitle} - Podcasty Radia Kielce`
        } else {
            document.title = `${props.player.podcastTitle} - Podcasty Radia Kielce`
        }

    }, [props.player.play]);


    const openPodcast = (id, src, title, duration) => {

        if (idplay === id) {
            props.setPlayer({
                source: src,
                id: id,
                title: title,
                play: false,
                duration: displayDuration(duration),
                podcastTitle: selectedPodcast ? selectedPodcast.title : null
            });

            document.title = `${selectedPodcast ? selectedPodcast.title : props.player.podcastTitle} - Podcasty Radia Kielce`;

            setIdplay(null);
        } else {
            props.setPlayer({
                source: src,
                id: id,
                title: title,
                play: true,
                duration: displayDuration(duration),
                podcastTitle: selectedPodcast ? selectedPodcast.title : null
            });
            setIdplay(id);


            document.title = `▶ ${selectedPodcast ? selectedPodcast.title : props.player.podcastTitle} - Podcasty Radia Kielce`;
        }


    };


    const showMoreEpizodes = () => {
        setShowOnPage(showOnPage + 10);
    };


    const displayDuration = (time) => {

        let tmp = new Date(time);

        let min = tmp.getMinutes();
        let sec = tmp.getSeconds();

        if (min < 10) min = '0' + min;
        if (sec < 10) sec = '0' + sec;

        return `${min}:${sec}`


    };

    const displayDatePodcast = (date) => {

        return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(0, 4)}`

    };




    return (
        <React.Fragment>

            <Container fluid style={{maxWidth: '1410px', position: 'relative', marginTop: '56px'}}>
                <p className='d-none d-lg-block' style={styles.breadcrumbs}>
                    <Link to={'/'}>
                        Podcasty
                    </Link> > <span className='font-weight-bold'>{selectedPodcast && selectedPodcast.title}</span></p>
                <Row className='pt-3 pt-lg-0'>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={4} md={4}>
                                {selectedPodcast ? <img className='podcastImg' src={selectedPodcast.image_original_url}
                                                        alt={selectedPodcast.title}/> : null}

                            </Col>

                            {loading ?
                                <Col xs={8} md={7} className='d-flex justify-content-center align-items-center'>
                                    <Loader
                                        type="Oval"
                                        color="#000"
                                        height={100}
                                        width={100}

                                    /> </Col> :

                                <Col xs={8} md={7}>
                                    <h1 className='podcastTitle'>{selectedPodcast && selectedPodcast.title}</h1>
                                    <Badge className='d-none d-lg-inline-block'
                                           style={styles.badgePink}>{selectedPodcast && selectedPodcast.category}</Badge>
                                    <p ref={descriptionPodcast} className='podcastDesc mb-0'
                                       onClick={() => toggleDescription()}
                                       style={toggleText ? {maxHeight: '190px', overflow: 'hidden'} : null}>
                                        {selectedPodcast && selectedPodcast.description}
                                    </p>
                                    {toggleText && descHeight > 190 ? <span>...</span> : null}

                                </Col>

                            }


                        </Row>
                    </Col>
                    <Col xs={12} md={3}>
                        <div className='dotsImage justify-content-end align-items-center d-none d-lg-flex'>
                            <img style={{width: '138px', height: 'auto'}} src={dotsRight} alt="dots"/>
                        </div>
                        <div className='d-none d-lg-block'>

                            {selectedPodcast && <Subscribe appleLink={selectedPodcast.itunes_url}
                                                           deezerLink={selectedPodcast.deezer_url}
                                                           spotifyLink={selectedPodcast.spotify_url}
                                                           spreakerLink={selectedPodcast.spreaker_url}
                            />}
                        </div>
                    </Col>
                </Row>

                <Row style={{marginTop: '46px'}}>
                    <Col md={12}>
                        <Accordion defaultActiveKey={0}>
                            <ul className='list-unstyled p-0 d-flex flex-column'>

                                {epizodes.length > 0 && epizodes.map((item, index) => (

                                    index < showOnPage ?
                                        <React.Fragment key={index}>
                                            <li style={styles.podcastList}>
                                                <div
                                                    className='d-flex flex-row align-items-center justify-content-start justify-content-lg-between w-100'>

                                                    {/*<Accordion.Toggle as="div" eventKey={index} style={item.description ? {cursor: 'pointer'} : null}>*/}
                                                        <img
                                                            onClick={() => openPodcast(item.episode_id, item.playback_url, item.title, item.duration)}
                                                            style={{marginRight: '24px', cursor: 'pointer'}} width="37px"
                                                            src={(props.player.id === item.episode_id && props.player.play) ? stop : play}
                                                            alt=""/>
                                                    {/*</Accordion.Toggle>*/}


                                                    <div
                                                        className='d-flex flex-column flex-lg-row align-items-center justify-content-start justify-content-lg-between w-100'>

                                                        <div
                                                            className='d-flex align-items-center justify-content-start w-100'>

                                                            <Accordion.Toggle as="span" eventKey={index} style={item.description ? {cursor: 'pointer'} : null}>
                                                                {item.title}
                                                            </Accordion.Toggle>

                                                        </div>

                                                        <div
                                                            className='d-flex flex-row justify-content-between justify-content-lg-end align-items-center w-100'>


                                                            <div className='d-flex align-items-center'>
                                                                {item.isNew !== "false" ?
                                                                    <Badge className='mr-2 newBadge'
                                                                           variant="danger">Nowy</Badge> : null}
                                                                <p className='m-0 mr-2 lastPublish'>{displayDatePodcast(item.published_at)}</p>
                                                            </div>
                                                            <div>
                                                                <p className='m-0'>{displayDuration(item.duration)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            {item.description ? <Accordion.Collapse eventKey={index} className='collapse-border'>
                                                <Card.Body>{item.description}</Card.Body>
                                            </Accordion.Collapse> : null}
                                        </React.Fragment>

                                        : null


                                ))}

                            </ul>
                        </Accordion>

                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button disabled={epizodes && epizodes.length <= showOnPage}
                                    onClick={() => showMoreEpizodes()} variant="dark" style={styles.showMore}>Pokaż
                                więcej</Button>
                        </div>
                    </Col>
                </Row>

                <PopularBox visibleClass={'d-none d-lg-flex'} xl={2} count={4}/>

                <Row className='d-block d-lg-none'>
                    <Col>
                        {selectedPodcast && <Subscribe appleLink={selectedPodcast.itunes_url}
                                                       deezerLink={selectedPodcast.deezer_url}
                                                       spotifyLink={selectedPodcast.spotify_url}
                                                       spreakerLink={selectedPodcast.spreaker_url}
                        />}
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    )

}


const styles = {
    breadcrumbs: {
        fontSize: '14px',
        color: '#A9A9A9',
        paddingTop: '50px'
    },
    badgePink: {
        backgroundColor: '#FFE7E7',
        borderRadius: '17px',
        color: '#666666',
        padding: '10px',
        fontWeight: 'normal',
        fontSize: '14px',
        marginRight: '8px',
        marginBottom: '8px',
        cursor: 'pointer'
    },
    podcastList: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '10px',
        backgroundColor: '#F6F7F9',
        padding: '15px',
        justifyContent: 'space-between',
        borderRadius: '12px',
        alignItems: 'center'
    },
    showMore: {
        borderRadius: '17px',
        backgroundColor: '#000',
        fontWeight: 'bold',
        fontSize: '14px',
        textTransform: 'uppercase'
    }
};


const mapDispatchToProps = dispatch => ({
    setPlayer: player => dispatch(setPlayer(player))
});

const mapStateToProps = state => ({
    player: state.player
});

export default connect(mapStateToProps, mapDispatchToProps)(Podcast)
